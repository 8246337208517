import React from 'react'
import Moment from 'moment'
import { Link } from "gatsby"
import { Card } from "react-bootstrap"
import "@styles/templates/post-card.scss"

const CardItem = function (post) {
  this.id = post.id
  this.title = post.title
  this.thumbnailPath = post.thumbnailPath
  this.categoryName = post.category.name
  this.desc = post.desc
  this.date = Moment(post.date).format('YYYY-MM-DD')
}

const PostCard = (props) => {
  let post = props.post
  let cardItem = new CardItem(post)
  let postPath = '/post/' + cardItem.id
    
  return (
      <Card
        className="post-card">
        <Link to={postPath}>
          <Card.Img
            variant="top"
            src={cardItem.thumbnailPath}/>
          <Card.Body>
            <div className="category-name">{cardItem.categoryName}</div>
            <Card.Title
              className="text-ellipsis-2">{cardItem.title}</Card.Title>
            <div className="date">{cardItem.date}</div>
          </Card.Body>
        </Link>
      </Card>
  )
}

export default PostCard