import React, { useEffect, useState } from 'react'
import Layout from "@components/layout"
import Prism from "prismjs"
import "@node_modules/prismjs/components/prism-c.min.js"
import "@node_modules/prismjs/components/prism-cpp.min.js"
import "@node_modules/prismjs/components/prism-css.min.js"
import "@node_modules/prismjs/components/prism-javascript.min.js"
import "@node_modules/prismjs/components/prism-java.min.js"
import Moment from 'moment'
import Clipboard from 'react-clipboard.js';
import { Tooltip, Row, Col } from "react-bootstrap"
import { Link } from 'gatsby'
import "@styles/templates/post-detail.scss"
import SEO from "@components/seo"
import Util from "@components/core/util"
// import PostCardSummerized from "@templates/post-card-summerized.js"
import PostCard from "@templates/post-card.js"


const PostItem = function (post) {
  let headerImagePath = Util.getHeaderImagePathFromThumbnailUrl(post.thumbnailPath)
  this.id = post.id
  this.title = post.title
  this.menu = post.menu
  this.menuUrlLink = post.menu.urlLink
  this.category = post.category
  this.thumbnailPath = Util.getThumbnailUrl(post.thumbnailPath)
  this.headerImagePath = Util.getThumbnailUrl(headerImagePath)
  this.desc = post.desc
  this.date = Moment(post.date).format('YYYY-MM-DD')
  this.content = post.content
}

const RelatedPostItem = function (post) {
  this.id = post.id
  this.title = post.title
  this.desc = post.desc
  this.thumbnailPath = Util.getThumbnailUrl(post.thumbnailPath)
  this.category = post.category
  this.menu = post.menu
  this.date = post.date
}

const isHome = false
const isPost = true
const isMenu = false
const linkKey = ''

const PostDetail = (props) => {
  /*
    props.path: '/post/1'
    props.uri: '/post/1'
    navigate(to, options): fn
    location: {}
    pageContext: Post 정보
   */
  console.log(props.pageContext)
  const allPosts = props.pageContext.allPosts
  const post = props.pageContext.post
  let postItem = new PostItem(post)
  let jsonLdFormatted = Util.getPostJsonLDFormatted(postItem)
  let menuUrlLink = postItem.menu.urlLink
  let seoUrl = `https://blog.buildit.kr` + props.pageContext.pathName
  let relatedPosts = []
  let currentPage = Util.getCurrentPageFromLocationProp(props)
  let fromSearch = Util.getValueFromLocationProps(props, 'fromSearch', false)

  if (fromSearch) {
    currentPage = 1
  }

  console.log(currentPage, fromSearch)

  // SEO url
  // if (props.location && props.location.href) seoUrl = props.location.href

  // Related Posts
  allPosts.forEach((post) => {
    let menu = post.menu
    if (String(postItem.id) !== String(post.id)) {
      if ((String(menu.id) === String(postItem.menu.id))) {
        relatedPosts.push(new RelatedPostItem(post))
      }
    }
  })

  if (relatedPosts.length > 3) {
    relatedPosts = Util.getShuffledArray(relatedPosts)
    relatedPosts = relatedPosts.slice(0, 3)
  }

  // Tooltip
  const [currentUrl, setCurrentUrl] = useState('')
  const [supportedQueryCommand, setSupportedQueryCommand] = useState(false)
  const [showTooltip, setShowTooltip] = useState(false)
  const [timeoutTooltip, setTimeoutTooltip] = useState(false)
  const copyUrlToClipboard = (e) => {
    setShowTooltip(true)
    if (timeoutTooltip) {
      clearTimeout(timeoutTooltip)
      setTimeoutTooltip(null)
    }
    setTimeoutTooltip(setTimeout(() => {
      setShowTooltip(false)
    }, 2000))
  }

  // const moveToMenu = () => {
  //   let loc = window.location
  //   window.location.href = loc.origin + menuUrlLink
  // }

  useEffect(() => {
    if (document && document.queryCommandSupported('copy')) {
      setSupportedQueryCommand(true)
    }
    setCurrentUrl(window.location.href)
    Prism.highlightAll()
  }, [])
    
  return (
    <Layout>
      <SEO 
        title={postItem.title}
        description={postItem.desc}
        url={seoUrl} 
        image={postItem.thumbnailPath}
        isHome={isHome}
        isPost={isPost}
        isMenu={isMenu}
        linkKey={linkKey}
        schemaOrgJSONLD={jsonLdFormatted} />

      <div className="post-detail">
        {/* Post page header */}
        <div className="common-page-header post-detail-header">
          <span className="title ">{postItem.menu.name}</span>
          <span className="sub-title">{postItem.category.name}</span>
        </div>
        <div className="header post-detail-max-width">
          {/* Post content Header */}
          <h1 className="post-title">{postItem.title}</h1>
          <div className="post-info clearfix">
            <span className="date float-left">{postItem.date}</span>
            {
              supportedQueryCommand &&
              <div className="copy-url cursor-pointer float-right">
                <Clipboard
                  className="btn-copy-url"
                  data-clipboard-text={currentUrl}
                  onSuccess={copyUrlToClipboard}>
                  URL 복사하기
                </Clipboard>
                <Tooltip
                  placement="top"
                  className={'copy-url-tooltip ' + (showTooltip ? 'show' : '')}>
                    복사되었습니다.
                </Tooltip>
              </div>
            }
          </div>
          {/* <div className="thumbnail-image">
            <div className="thumbnail-inner">
              <img
                className="header-image"
                src={postItem.headerImagePath}/>
            </div>
          </div> */}
        </div>
        <div className="container post-detail-max-width">
          {/* Post contents */}
          <div className="post-content"
            dangerouslySetInnerHTML={{
              __html: post.content
            }}></div>
        </div>
        <div className={ "footer " + (relatedPosts.length === 0 ? 'no-data' : '')}>
          <div className="block buttons">
            <Link
              className="btn-move-to-list btn btn-outline-primary"
              to={menuUrlLink}
              state={{
                currentPage: currentPage
              }}>목록으로</Link>
            <div className="division-line"></div>
          </div>
          {/* Related Posts */}
          <div className={"block related-posts"}>
            <div className="top clearfix">
              <span className="label float-left">관련 콘텐츠</span>
            </div>
            <Row className={ "post-card-list"}>
              {
                relatedPosts.map((cardItem, index) => {
                  return (
                    <Col
                      className="post-card-list-item"
                      xs="12"
                      md="4"
                      lg="4"
                      key={index}>
                      <PostCard
                        post={cardItem} />
                    </Col>
                  )
                })
              }
            </Row>
          </div>
        </div>
      </div>
    </Layout>)
}

export default PostDetail